<template>
  <div>
    <div class="user" v-for="(item, index) in dataInfo" :key="index">
      <div class="user-info">
        <div class="user-info-header">
          <div class="user-info-name">
            <div>{{ item.wuserName }}</div>
            <div>户号：{{ item.wuserNo }}</div>
          </div>
        </div>
        <div class="user-info-right" v-if="item.feeState">欠费停水</div>
      </div>
      <div class="user-address">
        <em class="user-address-lable" v-if="item.labelName && item.labelName.length > 0">
          {{ item.labelName }}
        </em>
        <span>{{ item.wuserAddress }}</span>
      </div>
      <div>
        <div class="user-card">
          <van-cell v-for="(list, listIndex) in item.orders" :key="listIndex">
            <div class="user-card-info">
              <div class="user-card-info-checkbox">
                <div class="ucic-mouth">{{ list.chargeMonth }} ({{ list.userNo }})</div>
                <div class="ucic-name">{{ list.chargeName }}</div>
                <!--                <div class="ucic-name">{{ list.userNo }}</div>-->
                <!--                <van-checkbox-->
                <!--                  v-model="list.checked"-->
                <!--                  :name="listIndex"-->
                <!--                  icon-size="18px"-->
                <!--                  @click="selectCheckBox(index)"-->
                <!--                >-->
                <!--                  <div class="ucic-mouth">{{ list.chargeMonth }}</div>-->
                <!--                  <div class="ucic-name">{{ list.chargeName }}</div>-->
                <!--                </van-checkbox>-->
              </div>
              <div class="user-card-info-cost" @click="feeDetails(item, list)">
                <div class="cost-money">
                  {{ isActual(list.waterFee) }}
                </div>
                <van-icon name="arrow" color="#CDCDCD" size="16px" />
              </div>
            </div>
          </van-cell>
        </div>
        <div class="user-payment">
          <van-cell center>
            <template #title>
              <div class="user-payment-mouth">余额： {{ item.arrearsAmount }}</div>
              <!--              <div class="user-payment-mouth">余额： ¥{{ isActual(item.arrearsAmount) }}</div>-->
              <!--              <div v-if="item.depositAmount" class="user-payment-name">-->
              <!--                <van-checkbox-->
              <!--                  v-model="item.depositType"-->
              <!--                  shape="square"-->
              <!--                  icon-size="15px"-->
              <!--                  @click="selectCheckBox(index)"-->
              <!--                  :disabled="item.isPrestore"-->
              <!--                >-->
              <!--                  预存可抵：¥{{ isActual(item.depositAmount) }}-->
              <!--                </van-checkbox>-->
              <!--              </div>-->
            </template>
            <template #default>
              <div>
                <van-button
                  class="user-payment-button"
                  round
                  plain
                  type="info"
                  size="mini"
                  @click="gotoPaymentPage(item)"
                  :disabled="item.isDisabled"
                  v-if="item.isDisabled"
                >
                  充值缴费
                </van-button>
                <div v-if="item.allowCharge && !item.isDisabled">
                  <wx-open-launch-weapp
                        :id='"launch-btn"+new Date().valueOf()+index'
                        username="gh_aceb9bd462ab"
                        :path="'main/pages/nativeindex/nativeindex?fr=p&pg=charge&ct=371500&sv=1&ch=37150001910103&bk=' +item.wuserNo"
                        style="width:80px;height:34px"
                      >
                        <script type="text/wxtag-template">
                          <style>.test-btn-charge {width: 100%;height: 100%;background: rgba(0,0,0,0);color: #1d6fe9;font-family: PingFangSC-Medium;font-size: 14px;border: 1px solid #1d6fe9;line-height: 30px;z-index: 3;outline: none;border-radius:8px;padding:1px 6px}</style>
                          <button class="test-btn-charge">充值缴费</button>
                        </script>
                  </wx-open-launch-weapp>
                </div>
                <van-button
                  v-if="!item.allowCharge"
                  @click="showNotify(item)"
                  class="par-button par-button-disabled"
                >
                  充值缴费
                </van-button>
              </div>
            </template>
          </van-cell>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog, Notify } from 'vant';
import { paymentOrder } from '../../../api/home';
import NP from 'number-precision';
import { isAmount } from '@/utils/validator';

export default {
  name: 'UserCard',
  props: {
    dataInfo: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {},

  computed: {
    isActual() {
      return (val) => {
        return isAmount(val);
      };
    },
  },
  methods: {
    showNotify(item) {
      Notify({ type: 'warning', message: `${item.reason}` });
    },
    feeDetails(data1, data2) {
      this.$router.push({
        name: 'FeeDetails',
        path: `/QueryPay/FeeDetails`,
        query: {
          wuserNo: data1.wuserNo,
          billId: data2.billId,
        },
      });
    },
    // 单个卡片的单选
    selectCheckBox(index) {
      let itemData = this.dataInfo[index];
      // 当前卡片的计算 总值：
      let itemTotal = 0;
      itemData.orders.map((item) => {
        if (item.checked) {
          itemTotal = NP.plus(itemTotal, Number(item.waterFee));
        }
      });
      // 判断是否置灰按钮
      itemData.isDisabled = !itemData.orders.some((item) => item.checked);
      if (!isNaN(Number(itemData.depositAmount)) && Number(itemData.depositAmount) > 0) {
        itemData.isPrestore = itemData.isDisabled;
      }
      // 如果勾选了预存可抵的 则【当前总值 - 预存可抵 = 还需支付】
      if (itemTotal > 0) {
        // 单个卡片的总金额 大于 0 如果 单个总金额 < 余额 则还需支付金额为0 ，调用后台接口
        if (itemData.depositType && !isNaN(Number(itemData.depositAmount))) {
          let actual = NP.minus(Number(itemTotal), Number(itemData.depositAmount));
          if (actual > 0) {
            itemTotal = actual;
          } else {
            itemTotal = 0;
          }
        }
      } else {
        this.$set(this.dataInfo[index], 'isDisabled', true);
      }
      itemData.arrearsAmount = itemTotal;
      // this.$emit('getDataVaule', this.dataInfo);
    },
    // 立即缴费
    async gotoPaymentPage(data) {
      let billId = [];
      let arrearsAmount = 0;
      if (!isNaN(Number(data.arrearsAmount)) && Number(data.arrearsAmount) >= 0) {
        Dialog.alert({
          title: '无需支付',
          message: '余额大于缴费金额，会自动抵扣!',
          confirmButtonColor: '#277FF2',
        });
        return;
      }
      this.$router.push({
        path: '/charge',
      });
    },
    // 微信支付：
    onBridgeReady(params) {
      console.log('接受的参数', params);
      var that = this;
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: params.appId, //公众号名称，由商户传入
          timeStamp: params.timeStamp, //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: params.nonceStr, //支付签名随机串，不长于 32 位
          package: params.packageValue, //统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: params.signType, //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: params.paySign, //支付签名
        },
        function(res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            console.log('成功', res.err_msg);
            that.$router.push({
              name: 'PaymentResult',
              path: '/QueryPay/PaymentResult',
              query: {
                name: 'QueryPay',
              },
            });
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            console.log('失败', res.err_msg);
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0 6px 16px 0 rgba(63, 70, 79, 0.12);
  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 0 16px;
    .user-info-header {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      align-items: center;
      .user-info-name {
        display: flex;
        flex-wrap: wrap;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 600;
        div:first-child {
          margin-right: 8px;
        }
      }
    }
    .user-info-right {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1px 6px;
      background: #f64357;
      border-radius: 0 8px 0 8px;
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #ffffff;
      letter-spacing: -0.3px;
    }

    &-division {
      color: #aeaeae;
    }
  }
  &-address {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    padding: 2px 16px 10px 16px;
    position: relative;
    &-lable {
      font-style: normal;
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);
      background: #ecf1fe;
      border-radius: 2px;
      border-radius: 2px;
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #637fb7;
      letter-spacing: 0;
      line-height: 16px;
      padding: 1px 5px;
      margin-right: 8px;
    }
    span {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
    }
  }
  .user-address::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0px;
    bottom: 0;
    left: 0px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  &-card {
    // border-top: 1px solid #f0f0f0;
    // border-bottom: 1px solid #f0f0f0;
    position: relative;
    &-info {
      display: flex;
      &-checkbox {
        /*display: flex;*/
        justify-content: center;
        .ucic-mouth {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
        }
        .ucic-name {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
      &-cost {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        .cost-money {
          font-family: PingFangSC-Regular;
          margin-right: 6px;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          letter-spacing: 0;
          line-height: 24px;
          text-align: right;
          div {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            letter-spacing: 0;
            text-align: right;
            line-height: 20px;
          }
        }
      }
    }
  }
  .user-card::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  &-payment {
    &-mouth {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
    &-button {
      padding: 16px 10px;
      border: 1 solid #0e6bf9;
      border-radius: 8px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #1d6fe9;
    }
  }
  /deep/ .user-payment-name .van-checkbox__label {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }
  .user-tips {
    display: flex;
    justify-content: center;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
    padding: 16px 0;
    position: relative;
  }
  .user-tips::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }

  .par-button {
    width: 80px;
    height: 34px;
    background: rgba(0,0,0,0);
    color: #1d6fe9;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    border: 1px solid #1d6fe9;
    line-height: 30px;
    z-index: 3;
    outline: none;
    border-radius: 8px;
    padding: 1px 6px;
    &-disabled {
      cursor: not-allowed;
      opacity: .5;
    }
  }
}
</style>
